import { NextRequest, NextResponse } from "next/server";
import langParser from "accept-language-parser";
import { locales, getLocalePartsFrom } from "./i18n";
import cookie from "cookie";

export const allowedPaths = [
  "/",
  "/auth",
  "/affiliates",
  "/create",
  "/post/*",
  "/faq",
  "/tags",
  "/tags/*",
  "/privacy",
  "/terms",
  "/upgrade",
  "/user/affiliate",
  "/user/premium",
  "/user/saved-posts",
  "/user/settings",
  "/2257",
  "/contact",
  "/billing",
  "/blog",
  "/blog/*",
  "/credits",
  "/reward-calendar",
];

const findBestMatchingLocale = (acceptLangHeader: string): string => {
  const parsedLangs = langParser.parse(acceptLangHeader);
  for (const parsedLang of parsedLangs) {
    const matchedLocale = locales.find((locale) => parsedLang.code === locale.split("-")[0]);
    if (matchedLocale) return matchedLocale;
  }
  return locales[0];
};

export function middleware(request: NextRequest) {
  const { pathname, searchParams } = request.nextUrl;
  const localeFromCookie = cookie.parse(request.headers.get("Cookie") || "").locale;

  const desiredLocale =
    localeFromCookie || findBestMatchingLocale(request.headers.get("Accept-Language") || "");

  const { lang: desiredLang, country: desiredCountry } = getLocalePartsFrom({
    locale: desiredLocale,
  });
  const desiredPathPrefix =
    desiredLang === "en" && desiredCountry === "us" ? "" : `/${desiredLang}/${desiredCountry}`;

  // If the request is for /sitemap.xml or /robots.txt, do not redirect, just proceed.
  if (pathname === "/sitemap.xml" || pathname === "/robots.txt") {
    return NextResponse.next();
  }

  // If the path starts with "/en/us", remove that segment and redirect
  if (pathname.startsWith("/en/us")) {
    const newPath = pathname.replace("/en/us", "") || "/";
    const absoluteURL = new URL(newPath + (searchParams ? "?" + searchParams : ""), request.url);
    return NextResponse.redirect(absoluteURL.toString());
  }

  // If it's the default locale (en-US) and the path doesn't have a prefix, simply proceed.
  if (desiredPathPrefix === "" && !pathname.includes("/")) {
    return NextResponse.next();
  }

  const city = request.headers.get("x-vercel-ip-city"); // Assuming this header exists
  const germanCities = [
    "Berlin",
    "Hamburg",
    "Munich",
    "Cologne",
    "Frankfurt",
    "Stuttgart",
    "Düsseldorf",
    "Dortmund",
    "Essen",
    "Leipzig",
    "Dresden",
    "Hanover",
    "Nuremberg",
    "Duisburg",
    "Bochum",
    "Wuppertal",
    "Bielefeld",
    "Bonn",
  ];

  // Check if 'geo_de' is already set to avoid redirect loop
  if (city && germanCities.includes(city) && searchParams.get("geo_de") !== "true") {
    const url = request.nextUrl.clone(); // Clone the URL to modify it
    url.searchParams.set("geo_de", "true"); // Set the query parameter
    return NextResponse.redirect(url); // Perform the redirect
  }

  // 302400 is 3.5 day
  // 1209600 is 14 day
  // 86400 is 1 day

  // If the path already starts with the correct prefix, simply proceed.
  if (pathname.startsWith(desiredPathPrefix)) {
    const response = NextResponse.next();
    // Regex to match '/post/[24-char Mongo ID]'
    // const postRegex = /\/post\/[a-f0-9]{24}$/;
    // // post page cached for 14 days
    // if (postRegex.test(pathname)) {
    //   // const url = request.nextUrl.clone();
    //   // url.search = ""; // get rid of the _rsc or any other query params
    //   // const response = NextResponse.rewrite(url);
    //   response.headers.set(
    //     "Cache-Control",
    //     "public, s-maxage=1209600, stale-while-revalidate=86400",
    //   );
    // }
    return response;
  }

  // Check if current path starts with a locale prefix.
  const existingPrefix = locales.find((locale) => {
    const { lang, country } = getLocalePartsFrom({ locale });
    return pathname.startsWith(`/${lang}/${country}`);
  });

  // If there's an existing prefix that doesn't match the desired one, strip it.
  const newPath = existingPrefix
    ? pathname.replace(
        `/${existingPrefix.split("-")[0]}/${existingPrefix.split("-")[1]}`,
        desiredPathPrefix,
      )
    : `${desiredPathPrefix}${pathname}`;

  return NextResponse.redirect(
    new URL(newPath + (searchParams ? "?" + searchParams : ""), request.url).toString(),
  );
}

export const config = {
  matcher: [
    "/((?!api|_next/static|_next/image|assets|favicon.ico|apple-icon.png|icon.png|sw.js).*)",
  ],
};
