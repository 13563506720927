export const locales = [
  "en-US",
  "ja-JP",
  "ko-KR",
  "zh-CN",
  "zh-TW",
  "es-MX",
  "de-DE",
  "fr-CA",
  "ru-RU",
  "pt-BR",
] as const;
export type ValidLocale = (typeof locales)[number];

type PathnameLocale = {
  pathname: string;
  locale?: never;
};
type ISOLocale = {
  pathname?: never;
  locale: string;
};

type LocaleSource = PathnameLocale | ISOLocale;

export const getLocalePartsFrom = ({ pathname, locale }: LocaleSource) => {
  if (locale) {
    const localeParts = locale.toLowerCase().split("-");
    return {
      lang: localeParts[0],
      country: localeParts[1],
    };
  } else {
    const pathnameParts = pathname!.toLowerCase().split("/");
    return {
      lang: pathnameParts[1],
      country: pathnameParts[2],
    };
  }
};

const dictionaries: Record<ValidLocale, any> = {
  "en-US": () => import("dictionaries/en-US.json").then((module) => module.default),
  "ja-JP": () => import("dictionaries/ja-JP.json").then((module) => module.default),
  "ko-KR": () => import("dictionaries/ko-KR.json").then((module) => module.default),
  "zh-CN": () => import("dictionaries/zh-CN.json").then((module) => module.default),
  "zh-TW": () => import("dictionaries/zh-TW.json").then((module) => module.default),
  "es-MX": () => import("dictionaries/es-MX.json").then((module) => module.default),
  "de-DE": () => import("dictionaries/de-DE.json").then((module) => module.default),
  "fr-CA": () => import("dictionaries/fr-CA.json").then((module) => module.default),
  "ru-RU": () => import("dictionaries/ru-RU.json").then((module) => module.default),
  "pt-BR": () => import("dictionaries/pt-BR.json").then((module) => module.default),
} as const;

export const getTranslator = async (locale: ValidLocale) => {
  const dictionary = await dictionaries[locale]();
  return (key: string, params?: { [key: string]: string | number }) => {
    let translation = key.split(".").reduce((obj, key) => obj && obj[key], dictionary);
    if (!translation) {
      return key;
    }
    if (params && Object.entries(params).length) {
      Object.entries(params).forEach(([key, value]) => {
        translation = translation!.replace(`{{ ${key} }}`, String(value));
      });
    }
    return translation;
  };
};
